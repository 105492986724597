import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, Grid, Typography } from "@mui/material";

import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";

import { useMarketplaceApi } from "api/marketplace";

import { MarketStrategyContext } from "../Strategy";
import Filter from "./Filter";
import Metrics from "./Metrics";
import Results from "./Results";

dayjs.extend(minMax);

const LiveTest = () => {
	const { t } = useTranslation("marketplace");
	const { filter, setGeneralMarketMetrics } = useContext(MarketStrategyContext);

	const [marketStrategyMetrics, setMarketStrategyMetrics] = useState();
	const { getMarketStrategyMetrics, getGeneralMarketMetrics } = useMarketplaceApi();
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (filter) {
			setProcessing(true);
			getMarketStrategyMetrics(filter)
				.then((data) => {
					const { marketStrategyMetric } = data?.data;
					const { backtestResults, livetestResults } = data?.data?.marketStrategyMetric ?? {};

					if (!livetestResults) return { marketStrategyMetric };

					const startDate = dayjs
						.min([
							...(backtestResults?.results?.length ? [dayjs(backtestResults?.results?.[0].date)] : []),
							...(livetestResults?.results?.length ? [dayjs(livetestResults?.results?.[0].date)] : []),
						])
						.format("YYYY-MM-DD");

					const endDate = dayjs
						.max([
							...(backtestResults?.results?.length
								? [dayjs(backtestResults?.results?.[backtestResults?.results?.length - 1].date)]
								: []),
							...(livetestResults?.results?.length
								? [dayjs(livetestResults?.results?.[livetestResults?.results?.length - 1].date)]
								: []),
						])
						.format("YYYY-MM-DD");
					return { startDate, endDate, marketStrategyMetric };
				})
				.then(({ startDate, endDate, marketStrategyMetric }) => {
					if (!marketStrategyMetric?.livetestResults) {
						setProcessing(false);
						return;
					}

					Promise.all([
						getGeneralMarketMetrics({ title: "TOT", startDate, endDate }),
						getGeneralMarketMetrics({ title: "BTC", startDate, endDate }),
					])
						.then((values) => {
							const tot = values[0].data.series;
							const btc = values[1].data.series;

							setGeneralMarketMetrics({ tot, btc });

							setMarketStrategyMetrics({
								...marketStrategyMetric,
								backtestResults: {
									...marketStrategyMetric?.backtestResults,
									results: marketStrategyMetric?.backtestResults?.results?.map(({ date, value }) => ({
										date,
										value,
										tot: tot[date],
										btc: btc[date],
									})),
								},
								livetestResults: {
									...marketStrategyMetric?.livetestResults,
									results: marketStrategyMetric?.livetestResults?.results?.map(({ date, value }) => ({
										date,
										value,
										tot: tot[date],
										btc: btc[date],
									})),
								},
							});
						})
						.finally(() => {
							setProcessing(false);
						});
				});
		}
	}, [filter]);

	return (
		<>
			{processing ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "400px",
						width: "100%",
						backgroundColor: "#F5F5F5",
					}}
				>
					<div
						style={{
							borderRadius: "50%",
							width: "200px",
							height: "200px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<svg width="120" height="82" viewBox="0 0 60 41" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M39.6986 40.5928C34.3197 40.5849 29.1634 38.4446 25.3599 34.6411C21.5564 30.8376 19.4161 25.6813 19.4082 20.3023V0.895165C19.4082 0.657753 19.5025 0.430059 19.6704 0.262183C19.8383 0.0943065 20.066 0 20.3034 0C25.6847 0 30.8457 2.13772 34.6509 5.94291C38.456 9.7481 40.5938 14.9091 40.5938 20.2904V39.6916C40.5946 39.8097 40.572 39.9267 40.5274 40.036C40.4827 40.1453 40.4169 40.2447 40.3337 40.3285C40.2505 40.4122 40.1516 40.4787 40.0426 40.524C39.9336 40.5694 39.8167 40.5928 39.6986 40.5928ZM21.1985 1.80226V20.3023C21.193 25.0586 23.0196 29.6343 26.299 33.0793C29.5784 36.5242 34.0586 38.5738 38.8094 38.8024V20.3023C38.806 15.5483 36.9755 10.9774 33.6965 7.53509C30.4175 4.0928 25.9409 2.04249 21.1926 1.80823L21.1985 1.80226Z"
								fill="#0F20E8"
							></path>
							<path
								d="M20.2964 21.1919H0.895165C0.657753 21.1919 0.430059 21.0976 0.262183 20.9297C0.0943066 20.7619 0 20.5342 0 20.2968C0 14.9154 2.1377 9.75447 5.94289 5.94928C9.74808 2.14408 14.9091 0.00634766 20.2904 0.00634766C20.5278 0.00634766 20.7555 0.100672 20.9234 0.268548C21.0912 0.436425 21.1856 0.6641 21.1856 0.901513V20.3027C21.184 20.5381 21.0898 20.7633 20.9234 20.9298C20.757 21.0962 20.5317 21.1904 20.2964 21.1919ZM1.79627 19.4016H19.3833V1.80863C14.7953 2.04258 10.4577 3.97191 7.21155 7.22251C3.96537 10.4731 2.04192 14.8133 1.81419 19.4016H1.79627Z"
								fill="#0F20E8"
							></path>
							<path
								d="M39.6969 40.5929C39.4595 40.5929 39.2318 40.4986 39.0639 40.3307C38.8961 40.1629 38.8018 39.9352 38.8018 39.6978V20.2965C38.8018 20.0591 38.8961 19.8314 39.0639 19.6636C39.2318 19.4957 39.4595 19.4014 39.6969 19.4014H59.1041C59.3415 19.4014 59.5692 19.4957 59.7371 19.6636C59.905 19.8314 59.9993 20.0591 59.9993 20.2965C59.993 25.6786 57.8517 30.8383 54.0455 34.6434C50.2393 38.4485 45.079 40.5882 39.6969 40.5929ZM40.5921 21.1917V38.7787C45.186 38.5585 49.5332 36.6351 52.7858 33.3836C56.0385 30.132 57.9634 25.7855 58.1851 21.1917H40.5921Z"
								fill="#0F20E8"
							></path>
						</svg>
					</div>
				</div>
			) : (
				<Grid container>
					<Grid item xs={12} sx={{ mt: 2 }}>
						<Filter />
					</Grid>

					{!marketStrategyMetrics?.livetestResults?.results && (
						<Typography sx={{ mt: 2 }}>{t("marketplace_strategy_page_charts_no_data_text")}</Typography>
					)}

					{marketStrategyMetrics?.livetestResults?.results && (
						<Grid item xs={12}>
							{/* https://github.com/recharts/recharts/issues/854 */}
							<Results results={marketStrategyMetrics?.livetestResults?.results} />
						</Grid>
					)}

					{marketStrategyMetrics?.livetestResults?.metrics && (
						<Grid item xs={12}>
							<Metrics metrics={marketStrategyMetrics?.livetestResults?.metrics} />
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
};

export default LiveTest;
