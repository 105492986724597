import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, CardContent, Grid, Icon, IconButton, Paper, Typography } from "@mui/material";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

import Config from "services/config";

import shortenNumber from "utils/shortenNumber";

import { ArrowMiniLeft, ArrowMiniRight, AverageProfit, Lightning, Manage, Plus, SpotUp2, Switch } from "images";

import PortfolioDialog, { PortfolioDetails } from "./PortfolioDialog";

const data = [
	{ name: "Group A", value: 400 },
	{ name: "Group B", value: 300 },
	{ name: "Group C", value: 300 },
	{ name: "Group D", value: 200 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const ExampleComponent = () => {
	return (
		<PieChart width={200} height={200}>
			<Pie
				data={data}
				cx={"50%"}
				cy={"50%"}
				innerRadius={50}
				outerRadius={60}
				fill="#8884d8"
				paddingAngle={5}
				dataKey="value"
				label
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
		</PieChart>
	);
};

export default function Portfolio({ portfolio, summary, onRefresh }) {
	const [currentPlatform, setCurrentPlatform] = useState();
	const [dialogOpen, setDialogOpen] = useState(false);

	const { t } = useTranslation("dashboard");
	const navigate = useNavigate();

	const handleExchange = () => {
		navigate("/exchange-link");
	};

	const AssetCard = ({ item }) => {
		return (
			<Card variant="outlined" sx={{ border: 0 }}>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Grid container>
						<Grid item xs={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<ExampleComponent />
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: 700,
									mt: 1,
								}}
							>
								{item?.platform?.name}
							</Typography>
							<Typography
								sx={{
									fontSize: "24px",
									color: (theme) => theme.palette.primary.main,
									mt: 1,
								}}
							>
								{`$${
									item?.stableAmounts?.totalUsdtAmount
										? shortenNumber(item?.stableAmounts?.totalUsdtAmount)
										: "0.00"
								}`}
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<PortfolioDetails item={item} />
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		);
	};

	return (
		<React.Fragment>
			{currentPlatform && currentPlatform?.portfolio?.assets && (
				<PortfolioDialog
					dialogOpen={dialogOpen}
					setDialogOpen={setDialogOpen}
					item={currentPlatform}
				></PortfolioDialog>
			)}
			<Grid container>
				<Grid item xs={6}>
					<Typography
						sx={{
							display: "flex",
							flexDirection: "row",
							fontSize: "24px",
							fontWeight: 700,
							color: (theme) => theme.palette.primary.main,
						}}
					>
						{t("dashboard_portfolio_title")}
					</Typography>
				</Grid>
				<Grid item xs={8} sx={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
					<Typography
						sx={{
							fontSize: "24px",
							color: (theme) => theme.palette.secondary.main,
						}}
					>
						{`$${shortenNumber(
							portfolio?.reduce(function (a, b) {
								return a + b["stableAmounts"]["totalUsdtAmount"];
							}, 0)
						)}`}
					</Typography>
					<IconButton onClick={onRefresh}>
						<Switch />
					</IconButton>
				</Grid>

				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={8}>
							<Paper
								sx={{
									backgroundColor: "#FFFFFF",
									padding: 2,
									height: "310px",
								}}
							>
								{!portfolio.length && (
									<Grid
										container
										spacing={0}
										align="center"
										justifyContent="center"
										direction="column"
									>
										<Grid item>
											<div style={{ marginTop: "100px" }}>
												<Box
													onClick={handleExchange}
													display="flex"
													alignItems="center"
													justifyContent="center"
													sx={{
														border: "2px dotted #e5e6fb",
														borderRadius: "0.4rem",
														width: "3rem",
														height: "3rem",
														cursor: "pointer",
													}}
												>
													<Plus />
												</Box>

												<Typography
													sx={{
														color: (theme) => theme.palette.primary.main,
														fontWeight: 700,
														mt: 3,
													}}
												>
													{t("dashboard_portfolio_add_exchange_card")}
												</Typography>
											</div>
										</Grid>
									</Grid>
								)}
								{portfolio && (
									<Carousel
										indicators={false}
										navButtonsAlwaysVisible={true}
										NextIcon={<ArrowMiniRight />}
										PrevIcon={<ArrowMiniLeft />}
										navButtonsProps={{
											style: {
												backgroundColor: "transparent",
											},
										}}
									>
										{portfolio?.map((item, i) => (
											<AssetCard key={i} item={item} />
										))}
									</Carousel>
								)}
							</Paper>
						</Grid>
						<Grid item xs={4}>
							<Grid container>
								<Grid item xs={6}>
									<Paper
										sx={{
											backgroundColor: "#FFFFFF",
											py: 3,
											px: 2,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											ml: 1,
											height: "151px",
										}}
									>
										<Icon
											sx={{
												width: 25,
												height: 35,
											}}
										>
											<Manage />
										</Icon>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 700,
												textAlign: "center",
											}}
										>
											{t("dashboard_info_position_count")}
										</Typography>
										<Typography
											sx={{
												fontSize: "24px",
												fontWeight: 700,
												color: (theme) => theme.palette.primary.main,
												mt: "2px",
												mb: "4px",
											}}
										>
											{summary?.totalPositionCount}
										</Typography>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper
										sx={{
											backgroundColor: "#FFFFFF",
											py: 3,
											px: 2,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											ml: 1,
											height: "151px",
										}}
									>
										<Icon
											sx={{
												width: 25,
												height: 35,
											}}
										>
											<Lightning />
										</Icon>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 700,
												mt: "16px",
												textAlign: "center",
											}}
										>
											{t("dashboard_info_success_rate")}
										</Typography>
										<Typography
											sx={{
												fontSize: "24px",
												fontWeight: 700,
												color: (theme) => theme.palette.primary.main,
												mt: "4px",
											}}
										>
											{summary?.successRate && `${summary?.successRate}%`}
										</Typography>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper
										sx={{
											backgroundColor: "#FFFFFF",
											py: 3,
											px: 2,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											ml: 1,
											mt: 1,
											height: "151px",
										}}
									>
										<Icon
											sx={{
												width: 30,
												height: 35,
											}}
										>
											<AverageProfit />
										</Icon>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 700,
												textAlign: "center",
												mt: "4px",
											}}
										>
											{t("dashboard_info_average_profit")}
										</Typography>
										<Typography
											sx={{
												fontSize: "24px",
												fontWeight: 700,
												color: (theme) => theme.palette.primary.main,
												mt: "4px",
											}}
										>
											{`${summary?.averageProfit ? shortenNumber(summary?.averageProfit) : "0"}%`}
										</Typography>
									</Paper>
								</Grid>
								<Grid item xs={6}>
									<Paper
										sx={{
											backgroundColor: "#FFFFFF",
											py: 3,
											px: 2,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											ml: 1,
											mt: 1,
											height: "151px",
										}}
									>
										<Icon
											sx={{
												width: 26,
												height: 25,
											}}
										>
											<SpotUp2 />
										</Icon>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 700,
												textAlign: "center",
												mt: "14px",
											}}
										>
											{t("dashboard_info_total_profit")}
										</Typography>
										<Typography
											sx={{
												fontSize: "24px",
												fontWeight: 700,
												color: (theme) => theme.palette.primary.main,
												mt: "4px",
											}}
										>
											{summary?.totalProfitAmount
												? `$${shortenNumber(summary?.totalProfitAmount)}`
												: `$0.00`}
										</Typography>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
